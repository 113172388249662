import React from 'react';
import logo from './thora.svg';
import './App.css';

function App() {
  return (
    <div className="App gradient-background">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="thora" />
        <div className='textCopy'>
        <p>
          web store coming soon
        </p>
        <a
          className="App-link"
          href="mailto:hello@thora.com.au"
          target="_blank"
          rel="noopener noreferrer"
        >
          hello@thora.com.au
        </a>
        </div>
      </header>
    </div>
  );
}

export default App;
